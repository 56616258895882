import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { InputGroup, Menu, Tree } from '@blueprintjs/core';
import { ChevronDown, ChevronUp, DocumentImport, Search } from '@carbon/icons-react';
import { Button } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import ConfigurationDialog from 'app/components/DataPanel/ConfigurationsPanel/ConfigurationDialog';
import PublishedTable from 'app/components/DataPanel/TablesPanel/PublishedTable/PublishedTable';
import UnpublishedTable from 'app/components/DataPanel/TablesPanel/UnpublishedTable/UnpublishedTable';
import ImportActivityDialog from 'app/components/ImportActivityDialog/ImportActivityDialog';

import { useData } from 'app/contexts/dataProvider';

import { SplitFeatures } from 'app/global/features';

import { useDeleteFile } from 'app/graphql/mutations/deleteFile';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { ConfigurationDialogType, DataPanelViews, TablesTab } from 'app/models/index';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import LookupTableDetail from './LookupTableDetail/LookupTableDetail';
import TableDetail from './TableDetail/TableDetail';
import style from './TablesPanel.module.pcss';

const b = block(style);

interface TablesPanelProps {
  setSelectedDataView: Dispatch<SetStateAction<DataPanelViews>>;
  isActivityExpanded: boolean;
  setIsActivityExpanded: Dispatch<SetStateAction<boolean>>;
}

const TablesPanel: React.FC<TablesPanelProps> = ({
  setSelectedDataView,
  isActivityExpanded,
  setIsActivityExpanded
}: TablesPanelProps) => {
  const { fileUploadInProgress, selectedTablesTab, setSelectedTableViewTab, selectedDataView, selectedTable } =
    useData();

  const [isTQMOn] = useTreatment(SplitFeatures.TQM);
  const [isSymonEmbedFlagOn] = useTreatment(SplitFeatures.SYMON_EMBED);

  const [showImportActivityDialog, setShowImportActivityDialog] = useState<boolean>(false);
  const [isSymonUploadModalVisible, setIsSymonUploadModalVisible] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);

  const showToast = useShowToast();
  const [deleteFile, { loading: deletingFile }] = useDeleteFile();

  const tabTreeNodes = [
    {
      id: TablesTab.ALL,
      label: formatMessage('ALL'),
      isSelected: selectedTablesTab === TablesTab.ALL
    },
    {
      id: TablesTab.ACTIVITY,
      label: formatMessage('ACTIVITY'),
      isSelected: selectedTablesTab === TablesTab.ACTIVITY,
      secondaryLabel: (
        <Button
          icon={isActivityExpanded ? ChevronUp : ChevronDown}
          minimal
          onClick={(e) => {
            e.stopPropagation();
            setIsActivityExpanded(!isActivityExpanded);
          }}
        />
      ),
      hasCaret: false,
      isExpanded: isActivityExpanded,
      childNodes: [
        {
          id: TablesTab.ACTIVITY_PUBLISHED,
          label: formatMessage('PUBLISHED'),
          isSelected: selectedTablesTab === TablesTab.ACTIVITY_PUBLISHED
        },
        {
          id: TablesTab.ACTIVITY_UNPUBLISHED,
          label: formatMessage('UNPUBLISHED'),
          isSelected: selectedTablesTab === TablesTab.ACTIVITY_UNPUBLISHED
        }
      ]
    },
    ...(isTQMOn
      ? [
          {
            id: TablesTab.LOOKUP,
            label: formatMessage('LOOKUP'),
            isSelected: selectedTablesTab === TablesTab.LOOKUP
          }
        ]
      : [])
  ];

  useEffect(() => {
    if (fileUploadInProgress.length === 0) {
      setShouldRefetch(true);
    }
  }, [fileUploadInProgress]);

  const handleSymonUploadModalVisibility = (value: boolean) => {
    setIsSymonUploadModalVisible(value);
  };

  const handleDeleteFile = () => {
    if (!selectedTable) return;
    deleteFile({
      variables: { fileId: selectedTable.tableId },
      onCompleted() {
        setShowConfirmDeleteDialog(false);
        showToast(
          <ToastMessage
            title={formatMessage('DELETE_TABLE_SUCCESS_TITLE')}
            message={formatMessage('DELETE_TABLE_SUCCESS_MESSAGE', { tableName: selectedTable.tableName })}
          />,
          'success'
        );
        setShouldRefetch(true);
      },
      onError() {
        setShowConfirmDeleteDialog(false);
        showToast(
          <ToastMessage
            title={formatMessage('DELETE_TABLE_FAILURE_TITLE')}
            message={formatMessage('DELETE_TABLE_FAILURE_MESSAGE', { tableName: selectedTable.tableName })}
          />,
          'danger'
        );
      }
    });
  };

  const MenuContent = (
    <Menu data-testid="grid-options-menu">
      <MenuItem
        text={formatMessage('IMPORT_FROM_CSV')}
        onClick={() => {
          setShowImportActivityDialog(true);
        }}
        data-testid="import-from-csv"
      />
      <MenuItem
        text={formatMessage('IMPORT_FROM_SYMON')}
        data-testid="import-from-symon"
        onClick={() => {
          setIsSymonUploadModalVisible((prevState) => !prevState);
        }}
      />
    </Menu>
  );

  const onNodeClick = (nodeData) => {
    setSelectedTableViewTab(nodeData.id);
  };

  const renderTable = () => {
    if (selectedTablesTab === TablesTab.ACTIVITY_PUBLISHED || selectedTablesTab === TablesTab.LOOKUP) {
      return (
        <PublishedTable
          data-testid="published-table-grid"
          shouldRefetch={shouldRefetch}
          setSelectedDataView={setSelectedDataView}
          setShouldRefetch={setShouldRefetch}
          onDeleteClick={() => setShowConfirmDeleteDialog(true)}
        />
      );
    } else if (selectedTablesTab === TablesTab.ACTIVITY_UNPUBLISHED) {
      return (
        <UnpublishedTable
          data-testid="unpublished-table-grid"
          setSelectedDataView={setSelectedDataView}
          shouldRefetch={shouldRefetch}
          setShouldRefetch={setShouldRefetch}
          onDeleteClick={() => setShowConfirmDeleteDialog(true)}
        />
      );
    } else {
      return (
        <div className={b('tablesGrid')}>
          <UnpublishedTable
            data-testid="unpublished-table-grid"
            setSelectedDataView={setSelectedDataView}
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            onDeleteClick={() => setShowConfirmDeleteDialog(true)}
          />
          <PublishedTable
            data-testid="published-table-grid"
            shouldRefetch={shouldRefetch}
            setSelectedDataView={setSelectedDataView}
            setShouldRefetch={setShouldRefetch}
            onDeleteClick={() => setShowConfirmDeleteDialog(true)}
          />
        </div>
      );
    }
  };

  const renderTables = () => (
    <div data-testid="tables-panel" className={b('tablesPanelWrapper')}>
      <div className={b('tabs')}>
        <Tree contents={tabTreeNodes} onNodeClick={onNodeClick} />
      </div>
      <div className={b('tablesContainer')}>
        <div className={b('tablesContent')}>
          <div>
            <div className={b('tablesToolbar')}>
              <InputGroup
                placeholder={formatMessage('SEARCH')}
                rightElement={
                  <div className={b('searchIcon')}>
                    <Search />
                  </div>
                }
                disabled
              />
              <div className={b('tablesActionItems')}>
                <div className={b('tablesFileOptions')}>
                  <span className={b('importButton')}>
                    {isSymonEmbedFlagOn ? (
                      <TextButton
                        testId={'import-button'}
                        type="button"
                        text={formatMessage('IMPORT')}
                        intent="primary"
                        icon={<DocumentImport />}
                        onClick={() => {
                          setShowImportActivityDialog((prevState) => !prevState);
                        }}
                      />
                    ) : (
                      <Popover content={MenuContent} placement={'bottom'}>
                        <TextButton
                          testId={'import-button'}
                          type="button"
                          text={formatMessage('IMPORT')}
                          intent="primary"
                          icon={<DocumentImport />}
                        />
                      </Popover>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {renderTable()}
        </div>
      </div>

      {showImportActivityDialog && (
        <ImportActivityDialog
          setShowImportActivityDialog={setShowImportActivityDialog}
          showImportActivityDialog={showImportActivityDialog}
          handleShouldRefetch={setShouldRefetch}
          data-testid="import-activity-dialog"
        />
      )}
      {isSymonUploadModalVisible && (
        <ConfigurationDialog
          dialogTitle={formatMessage('IMPORT_FROM_SYMON')}
          isModalVisible={isSymonUploadModalVisible}
          handleModalVisibility={handleSymonUploadModalVisibility}
          dialogType={ConfigurationDialogType.IMPORT}
        />
      )}
      {showConfirmDeleteDialog && selectedTable && (
        <ConfirmDeleteModal
          isOpen={showConfirmDeleteDialog}
          onConfirmDelete={() => {
            handleDeleteFile();
          }}
          onCancel={() => {
            setShowConfirmDeleteDialog(false);
          }}
          titleText={formatMessage('DELETE_TABLE')}
          bodyText={formatMessage(
            selectedTable.published
              ? 'DELETE_TABLE_WITH_REFERENCES_MESSAGE_WITH_WARNING'
              : 'DELETE_TABLE_WITH_REFERENCES_MESSAGE',
            { tableName: selectedTable.tableName }
          )}
          isSubmitting={deletingFile}
        />
      )}
    </div>
  );

  return (
    <div className={b()}>
      {isSymonEmbedFlagOn ? (
        <>
          {selectedDataView === DataPanelViews.DATA_OVERVIEW && renderTables()}
          {selectedDataView === DataPanelViews.TABLE_DETAIL && <TableDetail />}
          {selectedDataView === DataPanelViews.LOOKUP_TABLE_DETAIL && <LookupTableDetail />}
        </>
      ) : (
        renderTables()
      )}
    </div>
  );
};

export default TablesPanel;
